.labels-container {
  padding-left: 11px;
  padding-bottom: 5.5px;
  display: flex;
  flex-wrap: wrap;
  &.min {
    padding-bottom: 0px;
  }

  &:hover {
    .cancel-icon {
      display: inline-block;
    }
  }

  .label-container {
    margin: 3px 6px 6px 0;
    display: flex;
    align-items: center;
    box-shadow: inset 0 0 0 1px rgb(154 160 166 / 54%);
    font-size: 11px;
    line-height: 17px;
    text-align: center;
    position: relative;

    .label {
      min-width: 35px;
      display: inline-block;
      position: inherit;
      img {
        height: 20px;
      }
      span {
        padding: 5px;
      }
      &:hover {
        cursor: pointer;
        .cancel-icon {
          display: inline-block;
        }
      }

      .cancel-icon {
        background: url(/assets/images/svg/cancel.svg) no-repeat center;
        background-size: 16px 16px !important;
        height: 20px;
        width: 20px;
        opacity: 0.54;
        outline: none;
        vertical-align: middle;
        margin-left: 6px;
        display: inline-block;
      }
      @media (hover: hover) {
        /* CSS styles for non touch devices */
        .cancel-icon {
          display: none;
        }
      }
    }
  }
}

%icons {
  height: 46px;
  top: 1px;
  width: 46px;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.54;

  &:hover {
    opacity: 0.87;
  }

  &.pop::before {
    top: 45px;
  }
}

.header {
  padding-left: 15px;
  padding-right: 15px;

  .error {
    color: #f6aea9;
    cursor: default;
    font-size: smaller;
    font-style: italic;
    padding: 10px;
    font-size: 12.5px;
  }

  p {
    // color: #e8eaed;
    font-size: 1rem;
    line-height: 1.5rem;
    height: 25px;
    margin: 0;
  }

  .input-container {
    display: flex;
    align-items: center;
    height: 45px;

    .plus-icon {
      background-image: url(/assets/images/svg/plus.svg);
      width: 16px;
      height: 16px;
      margin-left: 5px;
      @extend %icons;
    }

    .check-icon {
      @extend %icons;
      background-image: url(/assets/images/svg/check3.svg);
      width: 16px;
      height: 16px;
      margin-left: 5px;
    }
  }

  .labels-container {
    @extend .input-container;

    .label-icon {
      background-image: url(/assets/images/svg/label-filled.svg);
      @extend %icons;
    }

    .pen-icon {
      background-image: url(/assets/images/svg/pen-filled.svg);
      @extend %icons;
    }

    .trash-icon {
      background-image: url(/assets/images/svg/trash-filled.svg);
      @extend %icons;
    }

    input:focus + .pen-icon {
      background-image: url(/assets/images/svg/check3.svg);
    }

    .label-icon:hover {
      background-image: url(/assets/images/svg/trash-filled.svg);
    }
  }
}

