.modal-container {
  position: fixed;
  display: flex;
  z-index: 1001;
  left: 0;
  top: 0;
  max-width: 100%; /* Ensure it doesn't exceed the viewport width */
  max-height: 100%; /* Ensure it doesn't exceed the viewport height */
  width: 100%;
  height: 100%;
  background-color: rgb(30, 30, 40);
  overflow: hidden;
  justify-content: center;
  align-items: center;
  .modal {
    border-color: rgb(30, 30, 40);
    border-style: solid;
    border-width: 1px;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    transition: all 0.4s;
    margin: unset;
    max-width: 700px;
    max-height: 98%;
    overflow-y: auto;
    overflow-x: inherit;
    width: 95%;
    background-color: white;
  }
}
.btn {
  margin-right: 15px;
  padding: 8px 24px;
  border-color: rgb(30, 30, 40);
  border-style: solid;
  border-width: 1px;
  color: rgb(30, 30, 40);
  background-color: white;
  cursor: pointer;
  font-weight: 600;
  &:hover {
    background-color: rgb(30, 30, 40);
    color: white;
  }
  &:disabled {
    color: rgb(176, 176, 181);
    background-color: white;
    background-image: none;
    border-color: rgb(176, 176, 181);
    cursor: not-allowed;
    font-weight: 300;
  }
}
.btn span {
  vertical-align: middle;
}

.btn-primary {
  margin-right: 15px;
  font-weight: 600;
  padding: 8px 24px;
  border-color: rgb(30, 30, 40);
  border-style: solid;
  border-width: 1px;
  color: white;
  background-color: rgb(30, 30, 40);
  cursor: pointer;
  &:hover {
    background-color: white;
    color: rgb(30, 30, 40);
    border: 1px solid rgb(30, 30, 40);
  }
  &:disabled {
    color: rgb(176, 176, 181);
    background-color: white;
    background-image: none;
    border-color: rgb(176, 176, 181);
    font-weight: 300;
    cursor: not-allowed;
  }
}
.btn.primary span {
  vertical-align: middle;
}

.input-body {
  line-height: 1.25rem;
  min-height: auto;
  padding: 13px 16px 12px 16px;

  .textHolder {
    width: 100%;
    border: 1px solid #5f6368;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 8px;
    padding-right: 8px;
    resize: vertical;
    transition: height 0.2s ease;
    margin-top: 10px;
    font-weight: normal;
    font-family: 'DM Sans Variable';
    font-size: unset;
  }
}
.ph {
  opacity: 0.5;
}
.hide {
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0;
}
.pwcheckbox {
  display: flex;
  justify-content: right;
  padding: 10px 30px 10px 0px;
}

.auth-main {
  border-radius: 8px;
  .auth-container {
    background-position-x: right;
    background-position-y: bottom;
    background-size: cover;

    .auth-title {
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 10px 15px;
      font-weight: 600;
      font-style: normal;
      line-height: 1.5rem;
      white-space: pre-wrap;
      word-wrap: break-word;
      &:focus-visible {
        outline: 0;
      }
    }
    .pin-body {
      font-size: 0.875rem;
      line-height: 1.25rem;
      padding: 13px 16px 12px 16px;
      .codeImage {
        background-color: dimgray;
        margin-top: 4px;
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.6),
          inset 0px -3px 8px rgba(255, 255, 255, 0.2);
      }
      .pinContainer {
        justify-content: center;
        display: flex;
        min-height: 37px;
        .pinImage {
          max-width: 300px;
          margin-top: 2px;
        }
      }
    }
    .auth-body {
      min-height: 46px;
      padding: 16px;

      display: flex;
      justify-content: center;
      &.ph {
        color: rgba(255, 255, 255, 0.702);
        position: absolute;
        width: 100%;
        pointer-events: none;
      }

      &:focus-visible {
        outline: 0;
      }
    }
  }
}
.button-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  flex-direction: row;
  align-items: stretch;
  position: relative;
  justify-content: space-evenly;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  button {
    text-align: center;
    text-decoration: none;
    border-color: rgb(30, 30, 40);
    border-style: solid;
    border-width: 1px;
    min-width: 100px;
  }
  button:not(:disabled):hover {
    transition: all 0.3s ease 0s;
    background-position: right center;
  }
}

textarea:focus {
  outline: 1px solid rgb(113, 99, 241);
  border: 1px solid rgb(113, 99, 241);
}

input {
  border-radius:0px !important ;
  accent-color: rgb(113, 99, 241);
}

