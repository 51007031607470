.archives-container {
  padding-left: 11px;
  padding-right: 11px;
  padding-bottom: 5.5px;

  &.min {
    padding-bottom: 0px;
  }

  &:hover {
    .cancel-icon {
      display: inline-block;
    }
  }

  .archive-container {
    margin: 6px 6px 0 0;
    display: inline-block;
    border-radius: 1px;
    box-shadow: inset 0 0 0 1px rgb(154 160 166 / 54%);
    font-size: 11px;
    line-height: 17px;
    text-align: center;
    position: relative;
    background-color: rgb(30 29 29 / 22%);

    .archive {
      min-width: 35px;
      padding: 3px 8px;
      display: inline-block;

      position: inherit;

      &:hover {
        .cancel-icon {
          display: inline-block;
        }
      }

      .cancel-icon {
        background: url(/assets/images/svg/cancel.svg) no-repeat center;
        background-size: 16px 16px !important;
        height: 20px;
        width: 20px;
        opacity: 0.54;
        outline: none;
        vertical-align: middle;
        margin-left: 6px;
        display: inline-block;
      }
      @media (hover: hover) {
        /* CSS styles for non touch devices */
        .cancel-icon {
          display: none;
        }
      }
    }
  }
}
