.cbox-ph-container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 9px 0px 9px 24px;

  &:hover {
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: rgb(126 126 126 / 44%);
  }
}

.cboxes {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
  white-space: pre-wrap;
  word-wrap: break-word;
  -webkit-line-clamp: 3;

  &.min {
    margin: 5px 0px 5px 13px;
  }
}

.cbox-container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 0px;

  &:hover {
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: rgb(126 126 126 / 44%);

    .cbox-move-icon:not(.hide),
    .cbox-cancel-icon {
      visibility: visible;
    }
  }
}

//? record body
.cbox-record-container {
  overflow: auto;
  min-width: 240px;
  &.min {
    width: 76%;
  }
}

.cbox-record {
  font-size: 0.875rem;
  line-height: 1.25rem;
  white-space: pre-wrap;
  word-wrap: break-word;
  padding-left: 5px;
  width: 100%;
  transition: all 1s;
  overflow-wrap: break-word;

  &.small {
    padding-left: 0px;
  }

  &.min {
    font-size: 0.75rem;
  }

  &.ph {
    position: absolute;
    pointer-events: none;
    width: 90%;
  }

  &:focus-visible {
    outline: 0;
  }
}

//? record completed

.cbox-done {
  text-decoration: line-through;
  color: #9aa0a6;
}

.cbox-done-container {
  display: flex;
  justify-content: flex-start;
  color: #9aa0a6;
  font-size: 0.875rem;
  line-height: 1.25rem;
  cursor: pointer;
  margin-left: 18px;
  margin-bottom: 5px;
}

.cbox-done-container:hover > div {
  opacity: 1;
}

.hr {
  border-bottom: 1px solid rgba(250, 250, 250, 0.1);
  margin: 14px 24px 14px 24px;
}

//? icons
%ic {
  background-size: 25px 25px !important;
  opacity: 0.54;
  outline: none;
}

.cbox-icon {
  cursor: pointer;
  @extend %ic;
  background: url(/assets/images/svg/box.svg) no-repeat center;
  width: 25px;
  min-width: 25px;
  height: 25px;

  &.done {
    background-image: url(/assets/images/svg/box-checked.svg) !important;
  }

  &:hover {
    opacity: 0.87;
  }
}

.cbox-plus-icon {
  background: url(/assets/images/svg/plus.svg) no-repeat center;
  padding: 8px;
  @extend %ic;
}

.cbox-cancel-icon {
  background: url(/assets/images/svg/cancel.svg) no-repeat center;
  padding: 13px;
  margin-right: 4px;
  visibility: hidden;
  margin-left: auto;
  @extend %ic;

  &.min {
    margin-right: 2px;
    padding: 11px;
  }

  &:hover {
    opacity: 0.87;
  }
}

.cbox-move-icon {
  background: url(/assets/images/svg/move.svg) no-repeat center;
  cursor: move;
  height: 24px;
  width: 24px;
  min-width: 24px;
  left: 0;
  opacity: 0.54;
  visibility: hidden;
}
/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .cbox-move-icon {
    visibility: visible;
  }
}

.cbox-arrow {
  background: url(/assets/images/svg/arrow.svg) no-repeat center;
  height: 24px;
  width: 24px;
  opacity: 0.54;

  &.rotate {
    transform: rotate(90deg);
  }
}
