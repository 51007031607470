.snackbar-container {
  z-index: 2147483646;
}

:root {
  --record-width: 240px;
  --sat-safe-area-bottom: env(safe-area-inset-bottom, 0px);
}

.help-item-container {
  display: flex;
  align-items: center; /* Aligns items vertically center */
  justify-content: flex-end; /* Separates the content and the help icon */
}

.help-icon {
  /* Existing CSS for the icon, add the following if not present */
  cursor: pointer;
  margin: 8px; /* Adjust as needed */
}

.main {
  background-color: white;
  color: rgb(30, 30, 40);
}

.H:hover {
  outline: none !important;
  // border-radius: 50% !important;
  background-color: rgba(252, 252, 252, 0.2) !important;
  cursor: pointer !important;
}

.w-100 {
  width: 100% !important;
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex !important;
}

.mx-auto {
  margin-inline: auto;
  transition: all 0.7s;
}

.pop:hover::before {
  transition: opacity 0.3s ease-in-out;
  content: attr(data-pop);
  position: absolute;
  width: max-content;
  transform: translate(-50%, 0);
  top: 37px;
  padding: 3px 9px;
  border-radius: 5px;
  z-index: 1002;
}

// /* ===== Scrollbar CSS ===== */

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  height: 16px;
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
  border: solid transparent;
  border-width: 1px 1px 1px 6px;
  box-shadow: inset 1px 1px 0 rgb(0 0 0 / 10%), inset 0 -1px 0 rgb(0 0 0 / 7%);
}

*::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: solid transparent;
  border-width: 0 0 0 4px;
}

//
img,
svg {
  vertical-align: middle;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "DM Sans Variable", sans-serif;
  font-style: normal;
  font-weight: normal;
  max-width: 100%;
  overflow-x: hidden;
}

button {
  font-family: "DM Sans Variable", sans-serif;
  font-style: normal;
  font-weight: 600;
}

// checkbox.scss
input[type="radio"],
input[type="checkbox"] {
  appearance: none; /* Remove default checkbox styling */
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  min-width: 20px;
  height: 20px;
  background-color: rgb(
    239,
    251,
    255
  ); /* Set background color for unchecked state */
  border: 1px solid black;

  cursor: pointer;
  outline: none;
  margin-right: 8px; /* Add space between checkbox and label */
  position: relative; /* Ensure the pseudo-element is positioned relative to the checkbox */
}

input[type="radio"]:checked,
input[type="checkbox"]:checked {
  background-color: rgb(
    95,
    194,
    217
  ); /* Set background color for checked state */
}

input[type="radio"]:checked::before,
input[type="checkbox"]:checked::before {
  content: ""; /* Create an empty element */
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px; /* Width of the black square */
  height: 10px; /* Height of the black square */
  background-color: black;
  transform: translate(-50%, -50%); /* Center the square inside the checkbox */
}

input[type="radio"]:disabled,
input[type="checkbox"]:disabled {
  background-color: white;
  border-color:  rgb(176, 176, 181);
  cursor: not-allowed;
}

//? fonts
@import "@fontsource-variable/dm-sans";

//? cbox
@import "./assets/scss/checkboxes.scss";

//? icons
@import "./assets/scss/icons.scss";

//? labels
@import "./assets/scss/labels.scss";

//? archives
@import "./assets/scss/archives.scss";

//? tooltips
@import "./assets/scss/tooltips.scss";

//? modals
@import "./assets/scss/modal.scss";

//? quill
@import "~quill/dist/quill.snow.css";

//? i18n dynamic inline styles set in [innerHTML]
@import "./assets/scss/i18n.scss";

//? snackbar
@import "./assets/scss/snackbar.css";

