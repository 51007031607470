.warning-container {
  background-color: #fff3cd;
  color: #856404;
  padding: 15px;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  margin-bottom: 15px;
}

.warning-bold {
  font-weight: bold;
}

.warning-margin-bottom {
  margin-bottom: 10px;
}

.warning-list {
  padding-left: 20px;
  margin-bottom: 10px;
}

.warning-italic {
  font-style: italic;
}

.test-warning {
  color: red;
  font-weight: bold;
  background-color: #ffd6d6;
  padding: 15px;
  margin: 10px;
  border: 1px solid red;
  border-radius: 8px;
}

.not-supported-error {
  color: red;
  font-weight: bold;
}

.not-supported-link {
  text-decoration: underline;
  color: blue;
}

.auth-option {
  margin-bottom: 20px;
}
