
.snackbar-container {
  transition: all 500ms ease;
  transition-property: top, right, bottom, left, opacity;
  font-size: 14px;
  min-height: 14px;
  background-color: white;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(0, 0, 0);
  line-height: 22px;
  padding: 12px 24px;
  bottom: -100px;
  top: -100px;
  opacity: 0;
  z-index: 9999;
}
.snackbar-container .action {
  background-color: #5ec2d9;
  display: inline-block;
  border: none;
  font-size: inherit;
  text-transform: uppercase;
  color: white;
  margin: 0 0 0 24px;
  padding: 8px;
  min-width: min-content;
  cursor: pointer;
}

.snackbar-cancel {
  img {
    width: 14px;
    right: 5px;
    top: 5px;
    position: absolute;
    cursor: pointer;
  }
}

@media (min-width: 640px) {
  .snackbar-container {
    min-width: 288px;
    max-width: 568px;
    display: inline-flex;
    border: 1px solid black;
    margin: 24px;
  }
}

@media (max-width: 640px) {
  .snackbar-container {
    left: 0;
    right: 0;
    width: 100%;
  }
}

.snackbar-pos.bottom-center {
  top: auto !important;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.snackbar-pos.bottom-left {
  top: auto !important;
  bottom: 0;
  left: 0;
}

.snackbar-pos.bottom-right {
  top: auto !important;
  bottom: 0;
  right: 0;
}

.snackbar-pos.top-left {
  bottom: auto !important;
  top: 0;
  left: 0;
}

.snackbar-pos.top-center {
  bottom: auto !important;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.snackbar-pos.top-right {
  bottom: auto !important;
  top: 0;
  right: 0;
}

@media (max-width: 640px) {
  .snackbar-pos.bottom-center,
  .snackbar-pos.top-center {
    left: 0;
    transform: none;
  }
}
